import { MouseEvent } from 'react'
import ReactorTile from './ReactorTile/ReactorTile'
import { NuclearReactor, NuclearReactorSize, NuclearTile } from './types'

import style from './Reactor.module.scss'

interface ReactorProps {
    reactor: NuclearReactor
    onClickTile?: (tile: NuclearTile, shiftKeyPressed: boolean) => void
}

export function Reactor({ reactor, onClickTile }: ReactorProps) {
    return (
        <div className={style.container}>
            <div className={style.grid} style={getGridTemplateStyle(reactor.size)}>
                {reactor.tiles.flatMap((row, rowIndex) =>
                    row.map((tile, columnIndex) => (
                        <ReactorTile
                            tile={tile}
                            onClick={onClickTile ? (event: MouseEvent) => onClickTile(tile, event.shiftKey) : undefined}
                            key={`${columnIndex}-${rowIndex}`}
                        />
                    ))
                )}
            </div>
        </div>
    )
}

function getGridTemplateStyle(size: NuclearReactorSize): React.CSSProperties {
    return {
        gridTemplateColumns: `repeat(${size}, 34px)`,
        gridTemplateRows: `repeat(${size}, 1fr)`,
    }
}
