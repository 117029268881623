import clone from 'just-clone'
import { useCallback, useState } from 'react'
import { arrayMean } from '../mathUtils'
import { getTileAtPos } from '../reactor/simulate'
import { NuclearReactor, NuclearReactorSize, ReactorSimulation } from '../reactor/types'
import { emptyReactorSimulation, forEachReactorPos } from '../reactor/utils'

type UseSimulationHistory = [ReactorSimulation, (reactor: NuclearReactor) => void, () => void]

export function useSimulationHistory(reactorSize: NuclearReactorSize): UseSimulationHistory {
    const [simulationHistory, setSimulationHistory] = useState<ReactorSimulation>(() =>
        emptyReactorSimulation(reactorSize)
    )

    const resetSimulationHistory = () => setSimulationHistory(emptyReactorSimulation(reactorSize))

    const append = useCallback(
        (reactor: NuclearReactor) => {
            const newSimulationHistory = clone(simulationHistory)

            forEachReactorPos(reactor.size, (x, y, index) => {
                const tileHistory = getTileAtPos(reactor, x, y)?.history

                if (tileHistory) {
                    const simulationHistoryTile = newSimulationHistory.tiles[index]

                    simulationHistoryTile.history.euGeneration.push(arrayMean(tileHistory.euGeneration))
                    simulationHistoryTile.history.fastNeutronFlux.push(arrayMean(tileHistory.fastNeutronFlux))
                    simulationHistoryTile.history.fastNeutronReceived.push(arrayMean(tileHistory.fastNeutronReceived))
                    simulationHistoryTile.history.fluidNeutronReactions.push(
                        arrayMean(tileHistory.fluidNeutronReactions)
                    )
                    simulationHistoryTile.history.neutronGeneration.push(arrayMean(tileHistory.neutronGeneration))
                    simulationHistoryTile.history.thermalNeutronFlux.push(arrayMean(tileHistory.thermalNeutronFlux))
                    simulationHistoryTile.history.thermalNeutronReceived.push(
                        arrayMean(tileHistory.thermalNeutronReceived)
                    )

                    ++simulationHistoryTile.historyLength
                }
            })
            setSimulationHistory(newSimulationHistory)
        },
        [simulationHistory, setSimulationHistory]
    )

    return [simulationHistory, append, resetSimulationHistory]
}
