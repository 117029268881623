import { getFuelEfficiency, getMeanNeutronAbsorption } from '../simulate'
import { NuclearTile } from '../types'
import { arrayMean } from '../../mathUtils'

import style from './ReactorTileInfo.module.scss'

interface ReactorTileInfoProps {
    tile: NuclearTile | null
}

export default function ReactorTileInfo({ tile }: ReactorTileInfoProps) {
    if (!tile) return <></>

    const toMillibucketsMultiplier =
        ((tile.item?.itemType === 'fluid' && tile.item.isHighPressure ? 0.125 : 1) * 20 * 60) / 81

    const fluxFastPerTick = arrayMean(tile.history.fastNeutronFlux)
    const fluxThermalPerTick = arrayMean(tile.history.thermalNeutronFlux)
    const fluxTotalPerTick = fluxFastPerTick + fluxThermalPerTick

    const NeutronGeneration = () => {
        if (!tile.fuel) {
            return null
        }
        const thermalEfficiency = getFuelEfficiency(tile.fuel, tile.temperature) * 100

        return (
            <div>{`${arrayMean(
                tile.history.neutronGeneration
            ).toFixed()} neutrons/t generation (${thermalEfficiency.toFixed(1)} % Thermal Efficiency)`}</div>
        )
    }

    return (
        <div className={style.container}>
            <div className={style.info}>
                <div>{`${tile.temperature.toFixed()} °C`}</div>
                <div>{`${getMeanNeutronAbsorption(tile, 'both').toFixed()} neutrons/t (${getMeanNeutronAbsorption(
                    tile,
                    'fast'
                ).toFixed(1)} fast, ${getMeanNeutronAbsorption(tile, 'thermal').toFixed(1)} thermal)`}</div>
                <div>{`${fluxTotalPerTick.toFixed()} neutrons/t flux (${fluxFastPerTick.toFixed()} fast, ${fluxThermalPerTick.toFixed()} thermal)`}</div>
                <NeutronGeneration />
                <div>{`${arrayMean(tile.history.euGeneration).toFixed()} EU/t steam generation`}</div>
                <div>{`${(
                    arrayMean(tile.history.fluidNeutronReactions) * toMillibucketsMultiplier
                ).toFixed()} mB/min fluid reactions`}</div>
            </div>
        </div>
    )
}
