export function clamp(num: number, minValue: number, maxValue: number) {
    return Math.max(Math.min(num, maxValue), minValue)
}

export function empty2dArray<Type>(width: number, height: number, defaultValue: Type): Type[][] {
    return Array(height)
        .fill(null)
        .map((x) =>
            Array(width)
                .fill(null)
                .map((y) => defaultValue)
        )
}

export function arrayMean(numberArray: number[]): number {
    return numberArray.reduce((a, b) => a + b, 0) / numberArray.length
}
