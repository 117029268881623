import axios from 'axios'
import { useEffect, useState } from 'react'
import { ReactorSimulationSummary } from '../reactor/types'

interface RequestStatePending {
    status: 'loading'
}

interface RequestStateError {
    status: 'error'
    error: any
}

interface RequestStateSuccess<TData> {
    status: 'success'
    data: TData
}

type RequestState<TData> = RequestStateError | RequestStatePending | RequestStateSuccess<TData>

interface ReactorListing {
    reactor: string
    summary: ReactorSimulationSummary
}

export default function useReactorTopList() {
    const [state, setState] = useState<RequestState<ReactorListing[]>>(() => ({ status: 'loading' }))

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/reactor`)
            .then((res) => setState({ status: 'success', data: res.data }))
    }, [])

    return state
}
