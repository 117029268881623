import { NuclearReactor } from './types'
import { computeReactorSummary, formatEuPerTick } from './utils'

interface ReactorStatsProps {
    reactor: NuclearReactor
}

export default function ReactorStats({ reactor }: ReactorStatsProps) {
    const stats = computeReactorSummary(reactor)
    const reactorEfficiency = ((stats.euGeneration / stats.euFuelConsumption) * 100).toFixed(1)

    const toMbPerMin = 1200 / 81

    return (
        <div style={{ fontFamily: 'monospace', fontWeight: '600', marginTop: '16px' }}>
            <div>{`Neutron absorptions per tick ${stats.neutronAbsorptions.toFixed()}`}</div>
            <div>{`EU generation ${formatEuPerTick(stats.euGeneration)} (${reactorEfficiency} % efficiency)`}</div>
            <div>{`EU fuel consumption ${formatEuPerTick(stats.euFuelConsumption)}`}</div>
            {stats.fluidReactionsWater > 0 && (
                <div>{`water reactons ${(stats.fluidReactionsWater * toMbPerMin).toFixed()} mB/min`}</div>
            )}
            {stats.fluidReactionsHeavyWater > 0 && (
                <div>{`heavy water reactons ${(stats.fluidReactionsHeavyWater * toMbPerMin).toFixed()} mB/min`}</div>
            )}
            {stats.fluidReactionsHighPressureWater > 0 && (
                <div>{`hp water reactons ${(
                    stats.fluidReactionsHighPressureWater *
                    toMbPerMin *
                    0.125
                ).toFixed()} mB/min`}</div>
            )}
            {stats.fluidReactionsHighPressureHeavyWater > 0 && (
                <div>{`hp heavy water reactons ${(
                    stats.fluidReactionsHighPressureHeavyWater *
                    toMbPerMin *
                    0.125
                ).toFixed()} mB/min`}</div>
            )}
            {stats.deuteriumProduction > 0 && (
                <div>{`deuterium production ${(stats.deuteriumProduction * toMbPerMin).toFixed()} mB/min`}</div>
            )}
            {stats.tritiumProduction > 0 && (
                <div>{`tritium production ${(stats.tritiumProduction * toMbPerMin).toFixed()} mB/min`}</div>
            )}
        </div>
    )
}
