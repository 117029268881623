import { BASE_HEAT_CONDUCTION, EU_FOR_FAST_NEUTRON, SCATTERING_TYPE } from './const'
import { FluidParams, IsotopeFuelParams, IsotopeParams, NeutronProduct, ScatteringType } from './types'
import { createNeutronBehavior, crossSectionFromProba, probaFromCrossSection, reduceCrossProba } from './utils'

function createIsotopeParams(
    thermalAbsorbProba: number,
    fastAbsorbProba: number,
    thermalScatteringProba: number,
    fastScatteringProba: number
): IsotopeParams {
    return {
        thermalAbsorption: crossSectionFromProba(thermalAbsorbProba),
        fastAbsorption: crossSectionFromProba(fastAbsorbProba),
        thermalScattering: crossSectionFromProba(thermalScatteringProba),
        fastScattering: crossSectionFromProba(fastScatteringProba),
    }
}

function createIsotopeFuelParams(
    thermalAbsorbProba: number,
    thermalScatterings: number,
    maxTemp: number,
    tempLimitLow: number,
    tempLimitHigh: number,
    neutronsMultiplication: number,
    directEnergyFactor: number
): IsotopeFuelParams {
    const isotopeParams = createIsotopeParams(
        thermalAbsorbProba,
        reduceCrossProba(thermalAbsorbProba, 0.1),
        thermalScatterings,
        reduceCrossProba(thermalScatterings, 0.5)
    )

    return {
        ...isotopeParams,
        maxTemp,
        neutronsMultiplication,
        directEnergyFactor,
        tempLimitLow,
        tempLimitHigh,
        //directEuByDesintegration: EU_FOR_FAST_NEUTRON * directEnergyFactor * neutronsMultiplication,
    }
}

function mix(a: number, b: number, r: number) {
    return r * a + (1 - r) * b
}

function mixIsotopeFuels(a: IsotopeFuelParams, b: IsotopeFuelParams, factor: number): IsotopeFuelParams {
    factor = 1 - factor

    const newThermalAbsorptionProba = probaFromCrossSection(mix(a.thermalAbsorption, b.thermalAbsorption, factor))
    const newScatteringProba = probaFromCrossSection(mix(a.thermalScattering, b.thermalScattering, factor))

    const newNeutronMultiplicationFactor = mix(a.neutronsMultiplication, b.neutronsMultiplication, factor)
    const totalEnergy = mix(
        a.neutronsMultiplication * (1 + a.directEnergyFactor),
        b.neutronsMultiplication * (1 + b.directEnergyFactor),
        factor
    )
    const newMaxTemp = Math.floor(mix(a.maxTemp, b.maxTemp, factor))
    const newTempLimitLow = Math.floor(mix(a.tempLimitLow, b.tempLimitLow, factor))
    const newTempLimitHigh = Math.floor(mix(a.tempLimitHigh, b.tempLimitHigh, factor))
    const newDirectEnergyFactor = totalEnergy / newNeutronMultiplicationFactor - 1

    return createIsotopeFuelParams(
        newThermalAbsorptionProba,
        newScatteringProba,
        newMaxTemp,
        newTempLimitLow,
        newTempLimitHigh,
        newNeutronMultiplicationFactor,
        newDirectEnergyFactor
    )
}

function createFluid(
    heatConduction: number,
    density: number,
    scatteringType: ScatteringType,
    params: IsotopeParams,
    neutronProduct: NeutronProduct,
    highPressure: boolean
): FluidParams {
    return {
        heatConduction: heatConduction * density,
        neutronBehavior: createNeutronBehavior(scatteringType, params, density),
        neutronProduct: neutronProduct,
        neutronProductAmount: highPressure ? 8 : 1,
        neutronProductProbability: highPressure ? 0.125 : 1,
    }
}

const U235 = createIsotopeFuelParams(0.6, 0.35, 2400, 900, 2300, 8, 0.5)
const U238 = createIsotopeFuelParams(0.6, 0.3, 3200, 1000, 3000, 6, 0.3)
const Pu239 = createIsotopeFuelParams(0.9, 0.25, 2100, 600, 2000, 9, 0.25)

export const U = mixIsotopeFuels(U238, U235, 1.0 / 81)
export const LEU = mixIsotopeFuels(U238, U235, 1.0 / 9)
export const HEU = mixIsotopeFuels(U238, U235, 1.0 / 3)
export const LE_MOX = mixIsotopeFuels(U238, Pu239, 1.0 / 9)
export const HE_MOX = mixIsotopeFuels(U238, Pu239, 1.0 / 3)

export const HYDROGEN = createIsotopeParams(0.1, 0.05, 0.25, 0.75)
export const DEUTERIUM = createIsotopeParams(0.02, 0.01, 0.15, 0.65)
export const CADMIUM = createIsotopeParams(0.95, 0.9, 0.05, 0.1)
export const CARBON = createIsotopeParams(0.01, 0.005, 0.5, 0.85)

export const WATER = createFluid(BASE_HEAT_CONDUCTION * 5, 1, SCATTERING_TYPE.ULTRA_LIGHT, HYDROGEN, 'deuterium', false)
// prettier-ignore
export const HIGH_PRESSURE_WATER = createFluid(BASE_HEAT_CONDUCTION * 5, 4, SCATTERING_TYPE.ULTRA_LIGHT, HYDROGEN, 'deuterium', true)

export const HEAVY_WATER = createFluid(BASE_HEAT_CONDUCTION * 6, 1, SCATTERING_TYPE.LIGHT, DEUTERIUM, 'tritium', false)
// prettier-ignore
export const HIGH_PRESSURE_HEAVY_WATER = createFluid(BASE_HEAT_CONDUCTION * 6, 4, SCATTERING_TYPE.LIGHT, DEUTERIUM, 'tritium', true)
