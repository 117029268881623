import { generateItems } from './items'
import { NuclearReactor, NuclearReactorSize } from './types'
import { createReactor, forEachReactorPos, getItemIndex, setItemAtPos } from './utils'

const _keyStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/='

export function serializeReactor(reactor: NuclearReactor): string {
    const serializationVersion = 0
    const encodedReactor: number[] = []

    encodedReactor.push(serializationVersion)
    encodedReactor.push(reactor.size)

    forEachReactorPos(reactor.size, (x, y) => {
        const tile = reactor.tiles[y][x]

        encodedReactor.push(getItemIndex(tile))
    })

    let stringEncoded = ''

    encodedReactor.forEach((itemIndex) => {
        stringEncoded = stringEncoded.concat(_keyStr.charAt(itemIndex))
    })

    return stringEncoded
}

export function deserializeReactor(serializedReactor: string): NuclearReactor | null {
    const encodedReactor: number[] = Array.from(serializedReactor)
        .map((char) => _keyStr.indexOf(char))
        .reverse()

    if (encodedReactor.includes(-1)) {
        return null
    }

    const serializationVersion = encodedReactor.pop()
    if (serializationVersion !== 0) {
        return null
    }
    const reactorSize = encodedReactor.pop() as NuclearReactorSize | undefined
    if (!reactorSize || ![3, 5, 7, 9].includes(reactorSize)) {
        return null
    }

    const reactor = createReactor(reactorSize)

    const itemList = generateItems()

    forEachReactorPos(reactorSize, (x, y) => {
        const itemIndex = encodedReactor.pop()
        if (itemIndex === undefined || itemIndex >= itemList.length) {
            return
        }
        setItemAtPos(itemList[itemIndex], reactor, x, y)
    })

    return reactor
}
