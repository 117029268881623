import { ScatteringType } from './types'

export const EU_FOR_FAST_NEUTRON = 8
export const DESINTEGRATION_BY_ROD = 10240000
export const BASE_HEAT_CONDUCTION = 0.01
export const BASE_NEUTRON = 0.1
export const MAX_TEMPERATURE = 3250
export const EU_PER_DEGREE = 64
export const MAX_HATCH_EU_PRODUCTION = 8192

export const SCATTERING_TYPE: Record<string, ScatteringType> = {
    ULTRA_LIGHT: {
        fastFraction: 0.05,
        slowFraction: 0.95,
    },
    LIGHT: {
        fastFraction: 0.2,
        slowFraction: 0.8,
    },
    MEDIUM: {
        fastFraction: 0.5,
        slowFraction: 0.5,
    },
    HEAVY: {
        fastFraction: 0.85,
        slowFraction: 0.15,
    },
}
