import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './pages/Home'
import TopList from './pages/TopList'

import style from './App.module.scss'

function App() {
    return (
        <div className={style.app}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/reactors" element={<TopList />} />
                </Routes>
            </BrowserRouter>
        </div>
    )
}

export default App
