import { generateItems } from '../items'
import { ReactorItem } from '../types'

import style from './ReactorItemSelector.module.scss'

interface ReactorItemSelectorProps {
    selectedItem?: ReactorItem
    setSelectedItem: (item: ReactorItem) => void
}

const ITEM_LIST = generateItems()

export default function ReactorItemSelector({ selectedItem, setSelectedItem }: ReactorItemSelectorProps) {
    return (
        <div className={style.container}>
            <div className={style.grid}>
                {ITEM_LIST.map((item, index) => (
                    <div
                        key={index}
                        className={`${style.item} ${item === selectedItem ? style.selected : ''}`}
                        onClick={() => setSelectedItem(item)}
                    >
                        {item.image ? <img src={item.image} alt="" width={32} height={32} /> : item.name}
                    </div>
                ))}
            </div>
        </div>
    )
}
