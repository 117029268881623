import useReactorTopList from '../hooks/useReactorTopList'
import { ReactorPreview } from '../reactor/ReactorPreview'
import { deserializeReactor } from '../reactor/serialization'
import { ReactorSimulationSummary } from '../reactor/types'
import { formatEuPerTick } from '../reactor/utils'

import style from './TopList.module.scss'

function ReactorStats({ reactorStats }: { reactorStats: ReactorSimulationSummary }) {
    return (
        <div>
            <pre>{`EU generation: ${formatEuPerTick(reactorStats.euGeneration)}`}</pre>
        </div>
    )
}

export default function TopList() {
    const req = useReactorTopList()

    const getContent = () => {
        switch (req.status) {
            case 'error':
                return <div>Failed to fetch reactor list</div>
            case 'loading':
                return <div>Loading...</div>
            case 'success':
                return (
                    <tbody>
                        {req.data
                            .sort((a, b) => b.summary.euGeneration - a.summary.euGeneration)
                            .map((reactor, i) => (
                                <tr>
                                    <td>{`#${i + 1}`}</td>
                                    <td>
                                        <ReactorPreview reactor={deserializeReactor(reactor.reactor)!} />
                                    </td>
                                    <td>
                                        <ReactorStats reactorStats={reactor.summary} />
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                )
        }
    }
    return (
        <div className={style.container}>
            <table>
                <thead>
                    <tr>
                        <th>Position</th>
                        <th>Reactor</th>
                        <th>Performance</th>
                    </tr>
                </thead>
                {getContent()}
            </table>
        </div>
    )
}
