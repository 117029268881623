import clone from 'just-clone'
import { arrayMean, clamp, empty2dArray } from '../mathUtils'
import { BASE_NEUTRON, EU_FOR_FAST_NEUTRON, EU_PER_DEGREE, MAX_HATCH_EU_PRODUCTION, MAX_TEMPERATURE } from './const'
import { NeutronHistory, NeutronType, NuclearFuel, NuclearReactor, NuclearTile } from './types'

const MAX_STEP = 100
const MAX_SPLIT = 30

const dX = [1, 0, -1, 0]
const dY = [0, 1, 0, -1]

export function getMeanNeutronAbsorption(tile: NuclearTile, neutronType: 'fast' | 'thermal' | 'both'): number {
    const history = tile.history
    if (neutronType === 'both') {
        return arrayMean(history.fastNeutronReceived) + arrayMean(history.thermalNeutronReceived)
    } else if (neutronType === 'fast') {
        return arrayMean(history.fastNeutronReceived)
    } else {
        return arrayMean(history.thermalNeutronReceived)
    }
}

function randIntFromDouble(value: number) {
    return Math.floor(value) + (Math.random() < value % 1 ? 1 : 0)
}

function simnulateDesintegration(tile: NuclearTile, fuel: NuclearFuel, neutronsReceived: number): number {
    const absorption = randIntFromDouble(neutronsReceived)
    return randIntFromDouble(getFuelEfficiency(fuel, tile.temperature) * absorption * fuel.neutronMultiplicationFactor)
}

function neutronGenerationTick(reactor: NuclearReactor, tile: NuclearTile): number {
    let meanNeutron = getMeanNeutronAbsorption(tile, 'both')
    let neutronsProduced = 0

    if (!tile.isFluid) {
        if (tile.isotope) {
            if (tile.fuel) {
                meanNeutron += BASE_NEUTRON
                neutronsProduced = simnulateDesintegration(tile, tile.fuel, meanNeutron)
            } else {
                // TODO: Maybe count like in MI so we can know how much durabilty decreases
            }
        }
        tile.history.neutronGeneration[reactor.currentHistoryStep] += neutronsProduced
    }
    return neutronsProduced
}

export function getFuelEfficiency(fuel: NuclearFuel, temperature: number): number {
    let factor = 1
    if (temperature > fuel.tempLimitLow) {
        factor = Math.max(0, 1 - (temperature - fuel.tempLimitLow) / (fuel.tempLimitHigh - fuel.tempLimitLow))
    }
    return factor
}

export function getTileAtPos(reactor: NuclearReactor, posX: number, posY: number): NuclearTile | null {
    if (posX >= 0 && posX < reactor.size && posY >= 0 && posY < reactor.size) {
        return reactor.tiles[posY][posX]
    }
    return null
}

interface NeutronSimulationContext {
    reactor: NuclearReactor
    neutronType: NeutronType
    currentTile: NuclearTile
    dir: number
    neutronNumber: number
}

export default function simulate(inputReactor: NuclearReactor): NuclearReactor {
    const reactor = clone(inputReactor)

    for (let i = 0; i < reactor.size; i++) {
        for (let j = 0; j < reactor.size; j++) {
            const tile = getTileAtPos(reactor, i, j)
            if (tile) {
                clearHistoryPos(tile.history, reactor.currentHistoryStep)
            }
        }
    }

    simulateNeutrons(reactor)
    simulateHeat(reactor)

    for (let i = 0; i < reactor.size; i++) {
        for (let j = 0; j < reactor.size; j++) {
            const tile = getTileAtPos(reactor, i, j)
            if (tile?.hasHatch) {
                tickNuclearTile(tile, reactor)
            }
        }
    }

    ++reactor.age
    reactor.currentHistoryStep = reactor.age % 100

    return reactor
}

function simulateNeutrons(reactor: NuclearReactor) {
    reactor.tiles.forEach((row, y) =>
        row.forEach((tile, x) => {
            const ctx: NeutronSimulationContext = {
                reactor,
                neutronType: 'fast',
                currentTile: tile,
                dir: 0,
                neutronNumber: 0,
            }
            if (!tile.isEmpty && tile.fuel) {
                // TODO: optimize this if check
                const neutronNumberPrime = neutronGenerationTick(reactor, tile)

                if (neutronNumberPrime > 0) {
                    const euHeat =
                        (neutronNumberPrime * tile.fuel.directEUbyDesintegration) /
                        tile.fuel.neutronMultiplicationFactor
                    putHeatOnTile(tile, euHeat)

                    const split = Math.min(neutronNumberPrime, MAX_SPLIT)
                    const neutronNumberPerSplit = Math.floor(neutronNumberPrime / split)

                    for (let k = 0; k < split + 1; k++) {
                        let neutronNumber = k < split ? neutronNumberPerSplit : neutronNumberPrime % split

                        if (neutronNumber > 0) {
                            ctx.neutronType = 'fast'
                            ctx.neutronNumber = neutronNumber
                            ctx.dir = Math.floor(Math.random() * 4)
                            let step = 0
                            let posX = x
                            let posY = y

                            while (step < MAX_STEP) {
                                step++
                                const secondTile = getTileAtPos(reactor, posX, posY)
                                if (secondTile?.hasHatch) {
                                    ctx.currentTile = secondTile
                                    if (processNeutronsTile(ctx)) {
                                        break
                                    }
                                } else {
                                    break
                                }
                                posX += dX[ctx.dir]
                                posY += dY[ctx.dir]
                            }
                        }
                    }
                }
            }
        })
    )
}

function simulateHeat(reactor: NuclearReactor) {
    const temperatureOut = empty2dArray(reactor.size, reactor.size, 0)
    const temperatureDelta = empty2dArray(reactor.size, reactor.size, 0)

    for (let step = 0; step < 3; step++) {
        // step 0: compute temperatureOut = dT * coeff
        // step 1: compute temperatureDelta, clamping as necessary
        // step 2: set temperature
        for (let i = 0; i < reactor.size; i++) {
            for (let j = 0; j < reactor.size; j++) {
                const tile = getTileAtPos(reactor, i, j)

                if (tile?.hasHatch) {
                    const temperatureA = tile.temperature
                    if (step === 2) {
                        tile.temperature = clamp(temperatureA + temperatureDelta[i][j], 0, MAX_TEMPERATURE)
                    } else {
                        if (step === 1) {
                            // clamp to avoid reaching < 0 temperatures
                            temperatureDelta[i][j] -= Math.min(temperatureA, temperatureOut[i][j])
                        }
                        for (let k = 0; k < 4; k++) {
                            const i2 = i + dX[k]
                            const j2 = j + dY[k]

                            const secondTile = getTileAtPos(reactor, i2, j2)

                            if (secondTile?.hasHatch) {
                                const temperatureB = secondTile.temperature
                                const coeffTransfer = 0.5 * (tile.heatTransferCoeff + secondTile.heatTransferCoeff)
                                if (temperatureA > temperatureB) {
                                    if (step === 0) {
                                        temperatureOut[i][j] += (temperatureA - temperatureB) * coeffTransfer
                                    } else {
                                        const frac = Math.min(1, temperatureA / temperatureOut[i][j])
                                        temperatureDelta[i2][j2] += frac * (temperatureA - temperatureB) * coeffTransfer
                                    }
                                }
                            } else {
                                const temperatureB = 0
                                const coeffTransfer = 0.5 * tile.heatTransferCoeff
                                if (step === 0) {
                                    temperatureOut[i][j] += (temperatureA - temperatureB) * coeffTransfer
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

function tickNuclearTile(tile: NuclearTile, reactor: NuclearReactor) {
    // TODO: Fluid neutron tick? If we want to know deuterium/tritium production
    if (tile.item?.itemType === 'fluid') {
        const neutron = randIntFromDouble(getMeanNeutronAbsorption(tile, 'both'))

        // Ignore high pressure. Effects of lower reaction probability and higher product yield cancel eachother out.
        const actualRecipe = randIntFromDouble(neutron)
        tile.history.fluidNeutronReactions[reactor.currentHistoryStep] += actualRecipe
    }

    if (tile.isFluid && tile.temperature > 100) {
        const euPerSteamMb = tile.item?.itemType === 'fluid' && tile.item.isHighPressure ? 8 : 1
        const steamProduction =
            (((tile.temperature - 100) / (MAX_TEMPERATURE - 100)) * MAX_HATCH_EU_PRODUCTION) / euPerSteamMb

        const euGenerated = steamProduction * euPerSteamMb
        tile.history.euGeneration[reactor.currentHistoryStep] = euGenerated
        tile.temperature = clamp(tile.temperature - euGenerated / EU_PER_DEGREE, 0, MAX_TEMPERATURE)
    }
    // TODO: checkComponentMaxTemperature
}

function processNeutronsTile(ctx: NeutronSimulationContext): boolean {
    addNeutronsToFlux(ctx)
    if (ctx.currentTile.neutronBehavior) {
        const nb = ctx.currentTile.neutronBehavior
        const interactionProba = ctx.neutronType === 'fast' ? nb.fastProbability : nb.thermalProbability

        if (Math.random() < interactionProba) {
            const interactionSelector = Math.random()
            const probaAbsorption =
                ctx.neutronType === 'fast'
                    ? nb.fastNeutronAbsorptionBarn / (nb.fastNeutronAbsorptionBarn + nb.fastNeutronScatteringBarn)
                    : nb.thermalNeutronAbsorptionBarn /
                      (nb.thermalNeutronAbsorptionBarn + nb.thermalNeutronScatteringBarn)

            if (interactionSelector <= probaAbsorption) {
                absorbNeutrons(ctx)

                if (ctx.neutronType === 'fast') {
                    putHeatOnTile(ctx.currentTile, ctx.neutronNumber * EU_FOR_FAST_NEUTRON)
                }

                if (ctx.currentTile.fuel) {
                    // Does nothing in the MI code
                }
                return true
            } else {
                ctx.dir = Math.floor(Math.random() * 4)

                if (ctx.neutronType === 'fast' && Math.random() < nb.neutronSlowingProbability) {
                    ctx.neutronType = 'thermal'
                    putHeatOnTile(ctx.currentTile, ctx.neutronNumber * EU_FOR_FAST_NEUTRON)
                }
            }
        }
    }
    return false
}

function absorbNeutrons(ctx: NeutronSimulationContext) {
    if (ctx.neutronType === 'fast') {
        ctx.currentTile.history.fastNeutronReceived[ctx.reactor.currentHistoryStep] += ctx.neutronNumber
    } else {
        ctx.currentTile.history.thermalNeutronReceived[ctx.reactor.currentHistoryStep] += ctx.neutronNumber
    }
}

function addNeutronsToFlux(ctx: NeutronSimulationContext) {
    const history =
        ctx.neutronType === 'fast'
            ? ctx.currentTile.history.fastNeutronFlux
            : ctx.currentTile.history.thermalNeutronFlux
    history[ctx.reactor.currentHistoryStep] += ctx.neutronNumber
}

function putHeatOnTile(tile: NuclearTile, eu: number) {
    tile.temperature = clamp(tile.temperature + eu / EU_PER_DEGREE, 0, MAX_TEMPERATURE)
}

function clearHistoryPos(history: NeutronHistory, pos: number) {
    history.euGeneration[pos] = 0
    history.fastNeutronFlux[pos] = 0
    history.fastNeutronReceived[pos] = 0
    history.neutronGeneration[pos] = 0
    history.thermalNeutronFlux[pos] = 0
    history.thermalNeutronReceived[pos] = 0

    // Statistics
    history.fluidNeutronReactions[pos] = 0
}
