import axios from 'axios'
import { serializeReactor } from './reactor/serialization'
import { NuclearReactor, ReactorSimulation } from './reactor/types'
import { computeReactorSummaryFromHistory } from './reactor/utils'

interface SubmitButtonProps {
    reactor: NuclearReactor
    simulationHistory: ReactorSimulation
}

export default function SubmitButton({ reactor, simulationHistory }: SubmitButtonProps) {
    const submit = () => {
        const summary = computeReactorSummaryFromHistory(reactor, simulationHistory)
        axios.post(`${process.env.REACT_APP_API_URL}/reactor`, {
            ...simulationHistory,
            reactor: serializeReactor(reactor),
            summary,
            version: 2,
        })
    }
    return <button onClick={submit}>Submit</button>
}
