import { BASE_HEAT_CONDUCTION, SCATTERING_TYPE } from './const'
import {
    CADMIUM,
    CARBON,
    HEAVY_WATER,
    HEU,
    HE_MOX,
    HIGH_PRESSURE_HEAVY_WATER,
    HIGH_PRESSURE_WATER,
    LEU,
    LE_MOX,
    U,
    WATER,
} from './isotopeSetup'
import { IsotopeFuelParams, ReactorItem } from './types'
import { createFuel, createNeutronBehavior } from './utils'

export const CASING_INDEX = 0
export const EMPTY_HATCH_INDEX = 1

export function generateItems(): ReactorItem[] {
    const itemList: ReactorItem[] = []

    let nextItemIndex = 0

    itemList.push({
        itemIndex: nextItemIndex++,
        itemType: 'noHatch',
        heatTransferCoeff: 0,
        name: 'CASING',
    })
    itemList.push({
        itemIndex: nextItemIndex++,
        itemType: 'emptyHatch',
        heatTransferCoeff: 0,
        name: 'EMPTY',
    })

    const fuelIsotopes: [IsotopeFuelParams, string, string][] = [
        [U, 'U', 'uranium'],
        [LEU, 'LE_U', 'le_uranium'],
        [HEU, 'HE_U', 'he_uranium'],
        [LE_MOX, 'LE_MOX', 'le_mox'],
        [HE_MOX, 'HE_MOX', 'he_mox'],
    ]

    fuelIsotopes.forEach(([fuelIsotope, name, fuelName]) => {
        const fuelSizes: [number, string][] = [
            [1, 'single'],
            [2, 'double'],
            [4, 'quad'],
        ]
        fuelSizes.forEach(([size, sizeName]) => {
            itemList.push({
                itemIndex: nextItemIndex++,
                itemType: 'fuel',
                heatTransferCoeff: BASE_HEAT_CONDUCTION * 0.8,
                name: name + ' x' + size,
                numRods: size,
                fuelParams: fuelIsotope,
                image: `${fuelName}_${sizeName}_rod.png`,
                fuel: createFuel(fuelIsotope, size),
            })
        })
    })

    itemList.push({
        itemIndex: nextItemIndex++,
        itemType: 'fluid',
        heatTransferCoeff: WATER.heatConduction,
        name: 'WATER',
        params: WATER,
        isHighPressure: false,
        image: 'water.png',
    })
    itemList.push({
        itemIndex: nextItemIndex++,
        itemType: 'fluid',
        heatTransferCoeff: HEAVY_WATER.heatConduction,
        name: 'HEAVY_WATER',
        params: HEAVY_WATER,
        isHighPressure: false,
        image: 'heavy_water.png',
    })
    itemList.push({
        itemIndex: nextItemIndex++,
        itemType: 'fluid',
        heatTransferCoeff: HIGH_PRESSURE_WATER.heatConduction,
        name: 'HIGH_PRESSURE_WATER',
        params: HIGH_PRESSURE_WATER,
        isHighPressure: true,
        image: 'high_pressure_water.png',
    })
    itemList.push({
        itemIndex: nextItemIndex++,
        itemType: 'fluid',
        heatTransferCoeff: HIGH_PRESSURE_HEAVY_WATER.heatConduction,
        name: 'HIGH_PRESSURE_HEAVY_WATER',
        params: HIGH_PRESSURE_HEAVY_WATER,
        isHighPressure: true,
        image: 'high_pressure_heavy_water.png',
    })
    itemList.push({
        // TODO max temp?
        itemIndex: nextItemIndex++,
        itemType: 'isotope',
        heatTransferCoeff: BASE_HEAT_CONDUCTION * 2,
        neutronBehavior: createNeutronBehavior(SCATTERING_TYPE.MEDIUM, CARBON, 2),
        name: 'LARGE_PLATE',
        params: CARBON,
        image: 'carbon_plate.png',
    })
    itemList.push({
        itemIndex: nextItemIndex++,
        itemType: 'isotope',
        heatTransferCoeff: BASE_HEAT_CONDUCTION * 0.5,
        neutronBehavior: createNeutronBehavior(SCATTERING_TYPE.HEAVY, CADMIUM, 1),
        name: 'CADMIUM_ROD',
        params: CADMIUM,
        image: 'cadmium_rod.png',
    })
    itemList.push({
        itemIndex: nextItemIndex++,
        itemType: 'other',
        heatTransferCoeff: BASE_HEAT_CONDUCTION * 15,
        name: 'SMALL_HEAT_EXCHANGER',
        image: 'small_heat_exchanger.png',
    })
    itemList.push({
        itemIndex: nextItemIndex++,
        itemType: 'other',
        heatTransferCoeff: BASE_HEAT_CONDUCTION * 30,
        name: 'LARGE_HEAT_EXCHANGER',
        image: 'large_heat_exchanger.png',
    })

    return itemList
}
