import { useState } from 'react'
import { NuclearReactor } from './reactor/types'
import { getShareLink } from './reactor/utils'

import style from './ShareButton.module.scss'

interface ShareButtonProps {
    reactor: NuclearReactor
}

export default function ShareButton({ reactor }: ShareButtonProps) {
    const [copied, setCopied] = useState<boolean>(false)
    const onClick = () => {
        navigator.clipboard.writeText(getShareLink(reactor)).then(() => {
            setCopied(true)
            setTimeout(() => {
                setCopied(false)
            }, 2000)
        })
    }

    const buttonText = copied ? 'Copied to clipboard!' : 'Copy reactor link'

    return (
        <div className={style.shareButton}>
            <button onClick={onClick} disabled={copied}>
                {buttonText}
            </button>
        </div>
    )
}
