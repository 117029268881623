import { MouseEvent } from 'react'
import ReactorTile from './ReactorTile/ReactorTile'
import { NuclearReactor, NuclearReactorSize, NuclearTile } from './types'

import style from './ReactorPreview.module.scss'

interface ReactorPreviewProps {
    reactor: NuclearReactor
}

export function ReactorPreview({ reactor }: ReactorPreviewProps) {
    return (
        <div className={style.container}>
            <div className={style.grid} style={getGridTemplateStyle(reactor.size)}>
                {reactor.tiles.flatMap((row, rowIndex) =>
                    row.map((tile, columnIndex) => <img src={tile.item?.image}></img>)
                )}
            </div>
        </div>
    )
}

function getGridTemplateStyle(size: NuclearReactorSize): React.CSSProperties {
    return {
        gridTemplateColumns: `repeat(${size}, 16px)`,
        gridTemplateRows: `repeat(${size}, 16px)`,
    }
}
