import { arrayMean } from '../mathUtils'
import { BASE_HEAT_CONDUCTION, EU_FOR_FAST_NEUTRON, SCATTERING_TYPE } from './const'
import { CASING_INDEX, EMPTY_HATCH_INDEX } from './items'
import { serializeReactor } from './serialization'
import { getTileAtPos } from './simulate'
import {
    IsotopeFuelParams,
    IsotopeParams,
    NeutronBehavior,
    NeutronHistory,
    NuclearFuel,
    NuclearReactor,
    NuclearReactorSize,
    NuclearTile,
    ReactorItem,
    ReactorSimulation,
    ReactorSimulationSummary,
    ReactorSimulationTile,
    ScatteringType,
} from './types'

export function probaFromCrossSection(crossSection: number): number {
    return 1 - Math.exp(-crossSection)
}

export function crossSectionFromProba(proba: number): number {
    return -Math.log(1 - proba)
}

export function reduceCrossProba(proba: number, crossSectionFactor: number): number {
    return probaFromCrossSection(crossSectionFromProba(proba) * crossSectionFactor)
}

export function createReactor(size: NuclearReactorSize): NuclearReactor {
    return {
        size,
        currentHistoryStep: 0,
        age: 0,
        tiles: Array(size)
            .fill(null)
            .map((ignored, y) =>
                Array(size)
                    .fill(null)
                    .map((ignored2, x) => ({
                        xPos: x,
                        yPos: y,
                        temperature: 0,
                        heatTransferCoeff: BASE_HEAT_CONDUCTION,
                        isEmpty: true,
                        history: emptyHistory(),
                        isFluid: false,
                        canHaveHatch: canHaveHatch(size, x, y),
                        hasHatch: canHaveHatch(size, x, y),
                    }))
            ),
    }
}

function canHaveHatch(reactorSize: NuclearReactorSize, x: number, y: number): boolean {
    const distanceFromCorner = Math.min(x, reactorSize - x - 1) + Math.min(y, reactorSize - y - 1)
    const minDistanceFromCorner: Record<NuclearReactorSize, number> = {
        '3': 0,
        '5': 1,
        '7': 2,
        '9': 2,
    }
    return distanceFromCorner >= minDistanceFromCorner[reactorSize]
}

const emptyHistory: (length?: number) => NeutronHistory = (length = 100) => ({
    fastNeutronReceived: Array(length).fill(0),
    thermalNeutronReceived: Array(length).fill(0),
    neutronGeneration: Array(length).fill(0),
    fastNeutronFlux: Array(length).fill(0),
    thermalNeutronFlux: Array(length).fill(0),
    euGeneration: Array(length).fill(0),
    fluidNeutronReactions: Array(length).fill(0),
})

export function createNeutronBehavior(
    scatteringType: ScatteringType,
    params: IsotopeParams,
    size: number
): NeutronBehavior {
    const thermalNeutronAbsorptionBarn = params.thermalAbsorption
    const fastNeutronAbsorptionBarn = params.fastAbsorption
    const thermalNeutronScatteringBarn = params.thermalScattering
    const fastNeutronScatteringBarn = params.fastScattering
    return {
        thermalNeutronAbsorptionBarn,
        fastNeutronAbsorptionBarn,
        thermalNeutronScatteringBarn,
        fastNeutronScatteringBarn,
        size,
        thermalProbability: probaFromCrossSection(
            (thermalNeutronAbsorptionBarn + thermalNeutronScatteringBarn) * Math.sqrt(size)
        ),
        fastProbability: probaFromCrossSection(
            (fastNeutronAbsorptionBarn + fastNeutronScatteringBarn) * Math.sqrt(size)
        ),
        neutronSlowingProbability: scatteringType.slowFraction,
    }
}

export function setItemAtPos(item: ReactorItem, reactor: NuclearReactor, posX: number, posY: number) {
    let neutronBehavior: NeutronBehavior | undefined
    let fuelIsotope: IsotopeFuelParams | undefined
    let isotope: IsotopeParams | undefined
    let fuel: NuclearFuel | undefined

    if (item.itemType === 'fuel') {
        neutronBehavior = createNeutronBehavior(SCATTERING_TYPE.HEAVY, item.fuelParams, item.numRods)
        isotope = item.fuelParams
        fuelIsotope = item.fuelParams
        fuel = item.fuel

        // forgot clamp temp! and conduction. NuclearFuel.java line 71 and 78
    }

    if (item.itemType === 'isotope') {
        neutronBehavior = item.neutronBehavior
        isotope = item.params
    }

    if (item.itemType === 'fluid') {
        neutronBehavior = item.params.neutronBehavior
    }

    const newTile: NuclearTile = {
        temperature: 0,
        item: item.itemType !== 'emptyHatch' && item.itemType !== 'noHatch' ? item : undefined,
        isEmpty: item.itemType === 'emptyHatch' || item.itemType === 'noHatch',
        isFluid: item.itemType === 'fluid',
        history: emptyHistory(),
        canHaveHatch: true,
        hasHatch: item.itemType !== 'noHatch',
        xPos: posX,
        yPos: posY,
        heatTransferCoeff: BASE_HEAT_CONDUCTION + item.heatTransferCoeff,
        neutronBehavior,
        isotope,
        fuel,
    }
    reactor.tiles[posY][posX] = newTile
}

export function forEachTile(reactor: NuclearReactor, f: (tile: NuclearTile, x: number, y: number) => void) {
    for (let i = 0; i < reactor.size; i++) {
        for (let j = 0; j < reactor.size; j++) {
            const tile = getTileAtPos(reactor, j, i)
            tile && f(tile, j, i)
        }
    }
}

export function forEachReactorPos(reactorSize: NuclearReactorSize, f: (x: number, y: number, index: number) => void) {
    let nextIndex = 0
    for (let i = 0; i < reactorSize; i++) {
        for (let j = 0; j < reactorSize; j++) {
            if (canHaveHatch(reactorSize, j, i)) {
                f(j, i, nextIndex++)
            }
        }
    }
}

export function getItemIndex(tile: NuclearTile): number {
    if (!tile.hasHatch) {
        return CASING_INDEX
    } else if (!tile.item) {
        return EMPTY_HATCH_INDEX
    } else {
        return tile.item.itemIndex
    }
}

export function formatEuPerTick(euPerTick: number): string {
    if (euPerTick < 10000) {
        return `${euPerTick.toFixed()} EU/t`
    }
    if (euPerTick < 100000) {
        return `${(euPerTick / 1000).toFixed(2)} kEU/t`
    }
    if (euPerTick < 1000000) {
        return `${(euPerTick / 1000).toFixed(1)} kEU/t`
    }
    return `${(euPerTick / 1000).toFixed()} kEU/t`
}

export function getShareLink(reactor: NuclearReactor): string {
    return `${window.location.protocol}//${window.location.host}?reactor=${serializeReactor(reactor)}`
}

export function emptyReactorSimulation(reactorSize: NuclearReactorSize): ReactorSimulation {
    const tiles: ReactorSimulationTile[] = []

    forEachReactorPos(reactorSize, (_x, _y, i) => {
        tiles.push({ tileIndex: i, history: emptyHistory(0), historyLength: 0 })
    })

    return {
        size: reactorSize,
        tiles,
    }
}

export function getFuelEuDensity(fuel: NuclearFuel) {
    return (1 + fuel.directEnergyFactor) * EU_FOR_FAST_NEUTRON * fuel.neutronMultiplicationFactor
}

function clampTemp(temperature: number): number {
    return 25 * Math.floor(temperature / 25)
}

export function createFuel(params: IsotopeFuelParams, size: number): NuclearFuel {
    return {
        size,
        directEnergyFactor: params.directEnergyFactor,
        neutronMultiplicationFactor: params.neutronsMultiplication,
        tempLimitLow: clampTemp(params.tempLimitLow),
        tempLimitHigh: clampTemp(params.tempLimitHigh),
        directEUbyDesintegration: EU_FOR_FAST_NEUTRON * params.directEnergyFactor * params.neutronsMultiplication,
        totalEUbyDesintegration: EU_FOR_FAST_NEUTRON * (1 + params.directEnergyFactor) * params.neutronsMultiplication,
        maxTemp: clampTemp(params.maxTemp),
    }
}

export function computeReactorSummary(reactor: NuclearReactor): ReactorSimulationSummary {
    let euGeneration = 0
    let euFuelConsumption = 0
    let neutronAbsorptions = 0
    let fluidReactionsWater = 0
    let fluidReactionsHeavyWater = 0
    let fluidReactionsHighPressureWater = 0
    let fluidReactionsHighPressureHeavyWater = 0

    forEachTile(reactor, (tile) => {
        euGeneration += arrayMean(tile.history.euGeneration)
        const tileNeutronAbsorptions =
            arrayMean(tile.history.fastNeutronReceived) + arrayMean(tile.history.thermalNeutronReceived)
        neutronAbsorptions += tileNeutronAbsorptions
        if (tile.fuel) {
            euFuelConsumption += tileNeutronAbsorptions * getFuelEuDensity(tile.fuel)
        }
        if (tile.item?.itemType === 'fluid') {
            switch (tile.item.name) {
                case 'WATER':
                    fluidReactionsWater += arrayMean(tile.history.fluidNeutronReactions)
                    break
                case 'HEAVY_WATER':
                    fluidReactionsHeavyWater += arrayMean(tile.history.fluidNeutronReactions)
                    break
                case 'HIGH_PRESSURE_WATER':
                    fluidReactionsHighPressureWater += arrayMean(tile.history.fluidNeutronReactions)
                    break
                case 'HIGH_PRESSURE_HEAVY_WATER':
                    fluidReactionsHighPressureHeavyWater += arrayMean(tile.history.fluidNeutronReactions)
                    break
            }
        }
    })

    const deuteriumProduction = fluidReactionsWater + fluidReactionsHighPressureWater
    const tritiumProduction = fluidReactionsHeavyWater + fluidReactionsHighPressureHeavyWater

    return {
        euGeneration,
        euFuelConsumption,
        neutronAbsorptions,
        fluidReactionsWater,
        fluidReactionsHeavyWater,
        fluidReactionsHighPressureWater,
        fluidReactionsHighPressureHeavyWater,
        deuteriumProduction,
        tritiumProduction,
    }
}

export function computeReactorSummaryFromHistory(
    reactor: NuclearReactor,
    simulation: ReactorSimulation
): ReactorSimulationSummary {
    let euGeneration = 0
    let euFuelConsumption = 0
    let neutronAbsorptions = 0
    let fluidReactionsWater = 0
    let fluidReactionsHeavyWater = 0
    let fluidReactionsHighPressureWater = 0
    let fluidReactionsHighPressureHeavyWater = 0

    forEachReactorPos(reactor.size, (x, y, i) => {
        const tileHistory = simulation.tiles[i].history
        euGeneration += arrayMean(tileHistory.euGeneration.slice(10))

        const tileNeutronAbsorptions =
            arrayMean(tileHistory.fastNeutronReceived.slice(10)) +
            arrayMean(tileHistory.thermalNeutronReceived.slice(10))
        neutronAbsorptions += tileNeutronAbsorptions

        const tile = getTileAtPos(reactor, x, y)

        if (tile?.fuel) {
            euFuelConsumption += tileNeutronAbsorptions * getFuelEuDensity(tile.fuel)
        }

        if (tile?.item?.itemType === 'fluid') {
            switch (tile.item.name) {
                case 'WATER':
                    fluidReactionsWater += arrayMean(tile.history.fluidNeutronReactions)
                    break
                case 'HEAVY_WATER':
                    fluidReactionsHeavyWater += arrayMean(tile.history.fluidNeutronReactions)
                    break
                case 'HIGH_PRESSURE_WATER':
                    fluidReactionsHighPressureWater += arrayMean(tile.history.fluidNeutronReactions)
                    break
                case 'HIGH_PRESSURE_HEAVY_WATER':
                    fluidReactionsHighPressureHeavyWater += arrayMean(tile.history.fluidNeutronReactions)
                    break
            }
        }
    })

    const deuteriumProduction = fluidReactionsWater + fluidReactionsHighPressureWater
    const tritiumProduction = fluidReactionsHeavyWater + fluidReactionsHighPressureHeavyWater

    return {
        euGeneration: Math.floor(euGeneration),
        euFuelConsumption: Math.floor(euFuelConsumption),
        neutronAbsorptions: Math.floor(neutronAbsorptions),
        fluidReactionsWater,
        fluidReactionsHeavyWater,
        fluidReactionsHighPressureWater,
        fluidReactionsHighPressureHeavyWater,
        deuteriumProduction,
        tritiumProduction,
    }
}
