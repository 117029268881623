import { NuclearTile } from '../types'
import { MouseEventHandler, useState } from 'react'
import ReactorTileInfo from '../ReactorTileInfo/ReactorTileInfo'
import { getFuelEuDensity } from '../utils'
import { DESINTEGRATION_BY_ROD } from '../const'

import style from './ReactorTile.module.scss'

interface ReactorTileProps {
    tile: NuclearTile
    onClick?: MouseEventHandler<HTMLDivElement>
}

export default function ReactorTile({ tile, onClick }: ReactorTileProps): JSX.Element {
    const [isMouseInside, setIsMouseInside] = useState<boolean>(false)
    const onMouseEnter = () => {
        console.log(tile)
        if (tile.fuel) {
            console.log(
                'Energy desnity ' +
                    getFuelEuDensity(tile.fuel) +
                    ' + ' +
                    getFuelEuDensity(tile.fuel) * tile.fuel.size * DESINTEGRATION_BY_ROD
            )
        }
        setIsMouseInside(true)
    }

    const onMouseLeave = () => {
        setIsMouseInside(false)
    }

    if (!tile.canHaveHatch) {
        return <div className={style.tile} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} />
    }
    if (!tile.hasHatch) {
        return (
            <div className={style.casing} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={onClick} />
        )
    }

    return (
        <div
            className={`${style.tile} ${style.hatch}`}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            {tile.item?.image && <img src={tile.item.image} alt="" width={32} height={32} />}
            {isMouseInside && tile && <ReactorTileInfo tile={tile} />}
        </div>
    )
}
